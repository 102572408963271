<template>
  <div>
    <b-modal
        id="modal-edit-user-bonus-request"
        cancel-variant="outline-secondary"
        ok-title="Ok"
        cancel-title="Close"
        ok-variant="primary"
        centered
        size="lg"
        title="Update Status Bonus Request"
        @ok="confirmUpdate"
        @hidden="resetModal()"
    >
      <validation-observer ref="editUserBonusRequest">
        <b-form
            ref=""
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
        >
          <b-col cols="12">
            <b-form-group label-for="status">
              <label class="mb-1 d-block"><span class="text-danger">*</span>{{ $t("Status") }}</label>
              <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
              >
              <b-form-radio
                  v-model="bonusStatusData.status"
                  name="status"
                  value="1"
                  inline
              >
                Pending
              </b-form-radio>
              <b-form-radio
                  v-model="bonusStatusData.status"
                  name="status"
                  value="2"
                  inline
              >
                Success
              </b-form-radio>
              <b-form-radio
                  v-model="bonusStatusData.status"
                  name="status"
                  value="3"
                  inline
              >
                Reject
              </b-form-radio>
              <b-form-radio
                  v-model="bonusStatusData.status"
                  name="status"
                  value="4"
                  inline
              >
                Cancel
              </b-form-radio>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="status">
              <label class="mb-1 d-block">Remark</label>
              <b-form-textarea
                  id="rejectReason"
                  v-model="bonusStatusData.remark"
                  placeholder="Remark"
                  rows="5"
                  maxlength=255
              />
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BImg,
  BFormSpinbutton,
  BFormRadio,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BOverlay,
    BImg,
    BFormSpinbutton,
    BFormRadio,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BFormFile,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userBonusDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bonusStatusData: {
        status: "",
        remark: "",
      }
    };
  },
  watch: {
    userBonusDetail: {
      handler(newVal) {
        if (newVal) {
          this.bonusStatusData = {
            id: newVal.id,
            status: newVal.statusId,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    resetModal() {
      this.$emit("update:userBonusDetail", null);
    },
    confirmUpdate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
    validationForm() {
      this.$refs.editUserBonusRequest.validate().then((success) => {
        if (success) {
          this.editUserBonusRequest(this.bonusStatusData);
        }
      });
    },
    async editUserBonusRequest(bonusStatusData) {
      await this.$store
          .dispatch("bonus/updateUserBonusRequestStatus", bonusStatusData)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === "00") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "success",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvModal.hide("modal-edit-user-bonus-request");
                    this.resetModal();
                  }, "500");
                });
                this.$emit("refetch-data");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            }
          });
    },
  },
  setup() {
    const statusOptions = [
      {label: "Pending", value: 1},
      {label: "Success", value: 2},
      {label: "Reject", value: 3},
      {label: "Cancel", value: 4},
    ];
    return {statusOptions};
  },
};
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>

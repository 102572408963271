<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>

                    <b-col cols="12" md="3" class="mb-0">
                        <label>{{ $t("Username") }}</label>
                        <b-form-input v-model="search.username" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-0">
                        <label>{{ $t("From Date") }}</label>
                        <flat-pickr id="fromDate" :config="formatFromDate" v-model="search.fromDate" class="form-control"
                            placeholder="YYYY-MM-DD" />
                    </b-col>
                    <b-col cols="12" md="3" class="mb-0">
                        <label>{{ $t("To Date") }}</label>
                        <flat-pickr id="fromDate" :config="formatFromDate" v-model="search.toDate" class="form-control"
                            placeholder="YYYY-MM-DD" />
                    </b-col>
                    <!-- action -->
                    <b-col cols="12" md="3" class="d-flex align-items-end justify-content-start">
                        <b-button variant="outline-secondary" @click="resetFilter()">
                            {{ $t("Reset") }}
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="searchFilter()">
                            {{ $t("Search") }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <div v-if="loading" class="text-center mt-2">
            <b-spinner class="align-middle"></b-spinner>
        </div>
        <b-card no-body v-else>
            <div style="margin: 15px; color: white" v-if="total > 0">
                Tổng:
                {{ total ? numberFormat(parseFloat(total) / 1000) : 0 }}
            </div>
            <b-table ref="refUserRollCallListTable" class="position-relative table-white-space mb-0 max-height-table"
                sticky-header head-variant="light" :items="userRollCallList" responsive :fields="tableColumns"
                primary-key="id" show-empty empty-text="No matching records found">

                <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + data.index + 1 }}
                </template>
                <!-- Column: User -->
                <template #cell(username)="data">
                    <b-link v-if="$can('view', 'userdetail')"
                        :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }">
                        {{ data.item.user.username }}
                    </b-link>
                    <span v-else class="text-primary">
                        {{ data.item.user.name }}
                    </span>
                </template>
                <template #cell(name)="data">
                    {{ data.item.user.name }}
                </template>
                <template #cell(phone)="data">
                    {{ data.item.user.phone }}
                </template>
                <template #cell(email)="data">
                    {{ data.item.user.email }}
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalSportTeam" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Edit User Bonus Request Modal -->
        <edit-user-bonus-request-modal :user-bonus-detail.sync="userBonusDetail" @refetch-data="refetchData" />
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
    VBTooltip,
    BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
import moment from "moment";
import EditUserBonusRequestModal from "@/views/bonus/EditUserBonusRequestModal";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        EditUserBonusRequestModal,
        flatPickr,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
        BSpinner
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            userBonusDetail: null,
        };
    },
    methods: {
        showEditBonusModal(data) {
            this.userBonusDetail = data;
            this.$bvModal.show("modal-edit-user-bonus-request");
        },
    },
    setup() {
        const toast = useToast();
        if (!store.hasModule("bonus"))
            store.registerModule("bonus", bonusStoreModule);
        onUnmounted(() => {
            if (store.hasModule("bonus")) store.unregisterModule("bonus");
        });

        const search = ref({
            username: "",
            bonus_id: "",
            status: "",
            fromDate: "",
            toDate: "",
        });
        const formatFromDate = {
            enableTime: false,
            dateFormat: "Y-m-d",
        };
        const perPage = ref(25);
        const totalSportTeam = ref(0);
        const currentPage = ref(1);
        const loading = ref(false);

        const refUserRollCallListTable = ref(null);
        const tableColumns = [
            { key: "index", label: "#" },
            { key: "username", label: "Username" },
            { key: "name", label: "Name" },
            // { key: "phone", label: "Phone" },
            // { key: "email", label: "Email" },
            { key: "amount_bonus", label: "Amount Bonus" },
            { key: "date", label: "Date" },
            { key: "created_at", label: "Created At" },
            { key: "updated_at", label: "Updated At" },
        ];

        const dataMeta = computed(() => {
            const localItemsCount = refUserRollCallListTable.value
                ? refUserRollCallListTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalSportTeam.value,
            };
        });

        const refetchData = () => {
            refUserRollCallListTable.value.refresh();
            getListUserBonusRequest();
        };

        watch([currentPage], () => {
            refetchData();
        });
        const userRollCallList = ref([]);
        const total = ref();
        const getListUserBonusRequest = (ctx, callback) => {
            loading.value = true;
            store
                .dispatch("bonus/fetchUserRollCall", {
                    username: search.value.username,
                    bonus_id: search.value.bonus_id,
                    status: search.value.status,
                    fromDate: search.value.fromDate,
                    toDate: search.value.toDate,
                    pageNumber: currentPage.value,
                })
                .then((response) => {
                    if (response.data.code == "00") {
                        let result = response.data.data;
                        userRollCallList.value = result
                        totalSportTeam.value = response.data.count;
                        total.value = response.data.total;
                    } else {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    }
                    loading.value = false;
                });
        };
        getListUserBonusRequest();

        const resetFilter = () => {
            // search.value.name = "";
            search.value.username = "";
            search.value.bonus_id = "";
            search.value.status = "";
            search.value.fromDate = "";
            search.value.toDate = "";
            refetchData();
        };

        const searchFilter = () => {
            getListUserBonusRequest();
        };

        return {
            tableColumns,
            perPage,
            currentPage,
            totalSportTeam,
            dataMeta,
            refUserRollCallListTable,
            getListUserBonusRequest,
            refetchData,
            search,
            resetFilter,
            numberFormat,
            formatDateTime,
            searchFilter,
            userRollCallList,
            formatFromDate,
            loading,
            total
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  